import React, { useRef } from 'react';
import icoTelegram from './ico-telegram.png';
import icoTwitter from './ico-twitter.png';
import icoMedium from './ico-medium.png';
import icoDextools from './ico-dextools.png';
import icoYoutube from './ico-youtube.png';
import { darkTheme, SwapWidget } from '@uniswap/widgets'
import '@uniswap/widgets/fonts.css'
import TOKENS from './tokens.json';
import { config, social } from './Config';
import Accordion from 'react-bootstrap/Accordion';
import LogoCarousel from './LogoCarousel';
import ContactForm from './ContactForm';
import BetaForm from './BetaForm';
import roadmap from './roadmap.png';

const Home = () => {
    const targetRef = useRef(null);

    const scrollToRef = (ref) => {
        window.scrollTo({
            top: ref.current.offsetTop,
            behavior: 'smooth',
        });
    };

    const handleClick = (e) => {
        e.preventDefault();
        scrollToRef(targetRef);
        window.history.pushState(null, null, targetRef);
    };

    const logos = [
        '/partner-1.png',
        '/partner-2.png',
        '/partner-3.png',
        '/partner-4.png',
        '/partner-5.png',
        '/partner-6.png',
        '/partner-7.png',
        '/partner-8.png',
        '/partner-9.png',
        '/partner-10.png',
        '/partner-11.png',
        '/partner-12.png'
    ];

    return (
        <>
            <section id="home" className="home">
                <div className="container">
                    <div className="home-big-title">{config.title}</div>
                    <div className="home-big-subtext">
                        ⚡️Elevate Altcoin Tactics with Cutting-Edge AI Technology⚡️
                    </div>
                    <div>
                        <a href="#use-cases" className="btn btn-brand" onclick={handleClick}>Learn how</a>
                    </div>
                </div>
            </section>
            <section id="use-cases" className="use-cases">
                <div className="container">
                    <div className="h1 section-title">
                        Godmode Agent
                    </div>
                    <div className="row mb-5">
                        <div className="col col-12 col-md-4 pb-3 pb-md-0">
                            <div className="box">
                                <div className="use-cases-item-title">Effortless Discovery</div>
                                <div className="use-cases-item-text">
                                    Explore the vast altcoin landscape effortlessly. Agent Alpha leverages advanced analytics to highlight tokens with the highest potential, providing a strong basis for your investment choices.
                                </div>
                            </div>
                        </div>
                        <div className="col col-12 col-md-4 pb-3 pb-md-0">
                            <div className="box">
                                <div className="use-cases-item-title">Comprehensive Analysis</div>
                                <div className="use-cases-item-text">
                                    Dive into the essentials. Agent Alpha covers everything from security to social trends, offering a holistic view of investment opportunities to keep you well-informed.
                                </div>
                            </div>
                        </div>
                        <div className="col col-12 col-md-4">
                            <div className="box">
                                <div className="use-cases-item-title">Tailored Trading</div>
                                <div className="use-cases-item-text">
                                    Select your trading strategy. Our platform provides flexibility with options like AutoPilot for hands-off trading, CoPilot for approval-based decisions, and Manual Scout for strategic control, aligning with your preferred trading style.
                                </div>
                            </div>
                        </div>
                    </div>
                    {social.gitbook ? <a className="btn btn-brand btn-lg" href={social.gitbook}>Check out whitepaper</a> : null}
                </div>
            </section>
            {/*
            <section id="roadmap" className="roadmap bg-black">
                <img src={roadmap} alt="Roadmap" />
            </section>
            */}
            {/*
            {config.token.active ? <section id="buy" className="buy">
                <div className="container">
                    <div className="h1 section-title">
                        ${config.token.symbol} token
                    </div>
                    <div className="row">
                        <div className="col col-12 col-md-6 pb-5 pb-md-0">
                            <div className="section-subtext">Buy {config.token.symbol} on Uniswap</div>
                            <ul className="token-info-list">
                                <li>Name (Symbol): {config.token.name} (${config.token.symbol})</li>
                                <li>Contract Address: <span className="contract-address">{config.token.contract}</span></li>
                                <li>Total Supply: {config.token.supply}</li>
                                <li>Tax & Fees: {config.token.fees}</li>
                                <li><a href={config.token.auditReport}>{config.token.audit}</a></li>
                            </ul>
                            {social.tokenMetrics ? <a className="btn btn-brand btn-lg" href={social.tokenMetrics}>Learn More</a> : null}
                            {config.token.active ? <a className="btn btn-brand btn-lg" href={social.uniswap}>Buy on Uniswap</a> : null}
                        </div>
                        <div className="col col-12 col-md-6">
                            <div className="uniswap-wrapper">
                                <div className="Uniswap">
                                    <SwapWidget
                                        theme={darkTheme}
                                        tokenList={TOKENS}
                                        defaultInputTokenAddress="NATIVE"
                                        defaultOutputTokenAddress={config.token.contract}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> : null}
            */}
            <section id="partners" className="partners bg-black">
                <div className="container">
                    <div className="section-title">Powered by</div>
                    <div className="partners-logos">
                        <LogoCarousel logos={logos} />
                    </div>
                </div>
            </section>
            <section className="features">
                <div className="container">
                    <div className="section-title">01. For Individuals</div>
                    <div className="section-subtext">Your new altcoin expert assistant, Agent Alpha</div>
                    <div className="features-accordion">
                        <Accordion defaultActiveKey="0" flush>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Find Tokens</Accordion.Header>
                                <Accordion.Body>
                                    Agent Alpha uncovers potential investments by scrutinizing key platforms such as Etherscan for token launches and Santiment for social insights, among others. This ensures a rich dataset that forms the basis for effective analysis of new and verified Ethereum tokens.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Analyze Fundamentals</Accordion.Header>
                                <Accordion.Body>
                                    With data in hand, Agent Alpha conducts a deep, holistic analysis. It delves into security risks, social trends, project fundamentals, and launch metrics, painting a detailed picture of each potential investment.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Execute Trade</Accordion.Header>
                                <Accordion.Body>
                                    Streamline your trading with Agent Alpha's tailored approach. Choose AutoPilot for hands-off execution, CoPilot for guided trades, or Manual Scout for strategic suggestions, all designed to suit your style.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                    <div>
                        <div className="features-extra-title">Spyglass Sentinel</div>
                        <div>
                            Peer into other portfolios with Spyglass Sentinel. Compare performance, follow top trades, and elevate your strategy by holding more $GOD than the wallets you monitor.
                        </div>
                    </div>
                </div>
            </section>
            <section className="features bg-black">
                <div className="container">
                    <div className="section-title">02. For Communities</div>
                    <div className="section-subtext">Supercharging crypto communities with Agent Alpha</div>
                    <div className="features-accordion">
                        <Accordion defaultActiveKey="0" flush>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Admin-Led Project Analysis</Accordion.Header>
                                <Accordion.Body>
                                    Group owners and admins can initiate detailed analyses of promising projects, leveraging Agent Alpha's comprehensive evaluation tools to inform and guide the community's investment strategies.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Community-Powered Scans</Accordion.Header>
                                <Accordion.Body>
                                    Members with an eye for opportunity can suggest projects for consideration. Through polls or upvotes, the community can democratically decide which ventures warrant a closer look, ensuring that the group's collective wisdom is harnessed.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Transparent Record-Keeping</Accordion.Header>
                                <Accordion.Body>
                                    Customize scans to include time-stamped posts that capture the price at the time of recommendation and set a time horizon. This feature allows for transparent, verifiable record-keeping of the group's investment performance over time.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>$GOD Leaderboard Integration</Accordion.Header>
                                <Accordion.Body>
                                    Opt-in to have your group's performance featured on the $GOD Leaderboard, showcasing the average rate of return on recommended projects. This not only fosters healthy competition but also highlights the most successful communities within the GodMode ecosystem.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                    <div>
                        <div className="features-extra-title">Spyglass Vanguard</div>
                        <div>
                            Elevate group intelligence with Spyglass Vanguard. Track influencer impact and compare against rival groups, sharpening your community's edge.
                        </div>
                    </div>
                </div>
            </section>
            <section className="features">
                <div className="container">
                    <div className="section-title">03. For Enterprise</div>
                    <div className="section-subtext">Enterprise solutions by Agent Alpha</div>
                    <div className="features-accordion">
                        <Accordion defaultActiveKey="0" flush>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Exclusive API Access</Accordion.Header>
                                <Accordion.Body>
                                    Gain direct access to our backend systems, including the bot and a dedicated enterprise-level priority queue, ensuring timely analyses and insights.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Private Token Scans</Accordion.Header>
                                <Accordion.Body>
                                    Conduct confidential scans of tokens and projects with complete anonymity. Our dashboard provides unrestricted access to a wealth of current and historical data, including social sentiment, market analytics, and more.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                    <div>
                        <div className="features-extra-title">Spyglass Executive</div>
                        <div>
                            Spyglass Executive offers a strategic vantage point, combining personal and community insights into a single, powerful enterprise dashboard for a comprehensive market overview.
                        </div>
                    </div>
                </div>
            </section>
            <section id="beta" className="beta-tester bg-black">
                <div className="container">
                    <div className="section-title">Apply as beta tester</div>
                    <div className="beta-form">
                        <BetaForm />
                    </div>
                </div>
            </section>
            <section id="customize">
                <div className="container">
                    <div className="section-title">Customize Your Experience</div>
                    <div className="row mb-5">
                        <div className="col col-12 col-md-4 pb-3 pb-md-0">
                            <div className="box">
                                <div className="use-cases-item-title">01 · Custom Sniping Strategies</div>
                                <div className="use-cases-item-text">
                                    Tailor your investment approach with settings that match your risk tolerance and goals, allowing Agent Alpha to adapt its scouting and sniping to your unique preferences.
                                </div>
                            </div>
                        </div>
                        <div className="col col-12 col-md-4 pb-3 pb-md-0">
                            <div className="box">
                                <div className="use-cases-item-title">02 · Live On-Chain & Social Info</div>
                                <div className="use-cases-item-text">
                                    Stay ahead with instant access to new token launches, social-trend analyses, and summary reports, ensuring you're always informed with the latest data.
                                </div>
                            </div>
                        </div>
                        <div className="col col-12 col-md-4 pb-3 pb-md-0">
                            <div className="box">
                                <div className="use-cases-item-title">03 · Automated Security Checks</div>
                                <div className="use-cases-item-text">
                                    Benefit from AGI-driven security assessments and comprehensive smart contract code reviews for peace of mind and clarity in your investment decisions.
                                </div>
                            </div>
                        </div>
                    </div>
                    {social.gitbook ? <a className="btn btn-brand btn-lg" href={social.gitbook}>Check out whitepaper</a> : null}
                </div>
            </section>
            <section id="contact" className="bg-black">
                <div className="container">
                    <div className="row">
                        <div className="col col-12 col-md-6">
                            <div className="section-title">Get in <br /> touch</div>
                            <div className="contact-email-wrapper">
                                <div className="contact-email">
                                    <a href={"mailto:" + config.email} title={config.title + " Email"}>{config.email}</a>
                                </div>
                                <div className="contact-email-subtext">GodMode Team</div>
                            </div>
                        </div>
                        <div className="col col-12 col-md-6">
                            <div className="contact-form">
                                <ContactForm />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="copyright" className="copyright">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col col-auto">© 2024 {config.title}</div>
                        <div className="col col-auto">
                            {social.privacy ? <a href={social.privacy} title={config.title + " Privacy"}>Privacy</a> : null}
                        </div>
                        <div className="col col-auto follow-icons">
                            {social.twitter ? <a href={social.twitter} target="_blank" rel="noopener noreferrer" title={config.title + " Twitter"}><img src={icoTwitter} alt={config.title + " Twitter"} /></a> : null}
                            {social.medium ? <a href={social.medium} target="_blank" rel="noopener noreferrer" title={config.title + " Medium"}><img src={icoMedium} alt={config.title + " Medium"} /></a> : null}
                            {social.telegram.channel ? <a href={social.telegram.channel} target="_blank" rel="noopener noreferrer" title={config.title + " Telegram Channel"}><img src={icoTelegram} alt={config.title + " Telegram Channel"} /></a> : null}
                            {social.telegram.group ? <a href={social.telegram.group} target="_blank" rel="noopener noreferrer" title={config.title + " Telegram Group"}><img src={icoTelegram} alt={config.title + " Telegram Group"} /></a> : null}
                            {social.youtube ? <a href={social.youtube} target="_blank" rel="noopener noreferrer" title={config.title + " Youtube"}><img src={icoYoutube} alt={config.title + " Youtube"} /></a> : null}
                            {social.dextools ? <a href={social.dextools} target="_blank" rel="noopener noreferrer" title={config.title + " Dextools"}><img src={icoDextools} alt={config.title + " DexTools"} /></a> : null}
                        </div>
                    </div>
                </div>
            </section >
        </>
    );
};

export default Home;
