import SmartContractAudit from "./GodModeToken_AuditReport_InterFi.pdf";

export const config = {
    domain: "godmodetoken.io",
    url: "https://www.godmodetoken.io",
    email: "info@godmodetoken.io",
    title: "GodMode",
    token: {
        active: true,
        name: "GodMode Token",
        symbol: "GOD",
        decimals: 18,
        contract: "0x2063516bA30D69F9075854A9F57d9c29f0C61b59",
        supply: "700 million",
        fees: "0/0",
        audit: `GodMode ($GOD) Smart Contract Audit by InterFi Network`,
        auditReport: SmartContractAudit
    }
};

export const social = {
    twitter: "https://x.com/GodMode_eth",
    medium: "https://medium.com/@godmodetoken",
    telegram: {
        channel: "https://t.me/Godmodetoken_eth",
        group: null
    },
    youtube: null,
    dextools: "https://www.dextools.io/app/en/ether/pair-explorer/0x9687702169470eb6aa35e106d65c70c4d1a0823e?t=1716912915103",
    etherscan: `https://etherscan.io/token/${config.token.contract}`,
    uniswap: `https://app.uniswap.org/#/swap?outputCurrency=${config.token.contract}`,
    github: null,
    gitbook: null,
    privacy: null,
    tokenMetrics: null
};
