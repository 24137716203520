import React, { useState } from 'react';
import axios from 'axios'; // Make sure to install axios for API calls
import { config } from './Config';

const BetaForm = () => {
  const [email, setEmail] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevents the default form submission

    // Perform the API call
    try {
      const response = await axios.post(config.url + '/beta.php', {
        email
      });

      // Check the response
      if (response.data.status === 'ok') {
        setFormSubmitted(true);
        setResponseMessage(response.data.message); // Assuming the API returns a message
      } else {
        setErrorMessage('An error occurred. Please try again.');
      }
    } catch (error) {
      setErrorMessage('An error occurred. Please try again.');
    }
  };

  // Display the response message instead of the form if submitted successfully
  if (formSubmitted) {
    return <div>{responseMessage}</div>;
  }

  return (
    <form onSubmit={handleSubmit}>
      {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
      <div className="form-floating mb-3">
        <input
          type="email"
          className="form-control"
          id="contact-email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label htmlFor="contact-email">Email</label>
      </div>
      <div className="text-center">
        <button type="submit" className="btn btn-brand btn-lg">Apply</button>
      </div>
    </form>
  );
};

export default BetaForm;
