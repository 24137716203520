import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const LogoCarousel = ({ logos }) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Carousel responsive={responsive} autoPlay={true} autoPlaySpeed={3000} infinite={true}>
      {logos.map((logo, index) => (
        <div key={index} style={{ display: "flex", justifyContent: "center" }}>
          <img src={logo} alt={`Logo ${index}`} /> {/* Adjust size as needed */}
        </div>
      ))}
    </Carousel>
  );
};

export default LogoCarousel;
